<template>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="block_container">
          <div class="bloc1">
            <h5>Reporte de Ventas {{report && '(' + report.length + ')' }}</h5>
          </div>
        </div>
      </div>
    </div>
    <div :class="`col-12 lg:col-${getTableLayoutClass()}`">
      <div class="card p-fluid" id="number-container">
        <Filter
            @get-data="getData"
            page="report-sales"
            v-if="identity.roleId!==7"/>

        <FilterAnalyst
            @get-data="getData"
            page="report-sales"
            v-if="identity.roleId===7"/>

      </div>
    </div>

    <div :class="`col-12 lg:col-${12-getTableLayoutClass()}`">
      <div class="card">
        <DataTable :value="report"
                   class="p-datatable-gridlines p-datatable-striped p-datatable-sm"
                   showGridlines
                   ref="dt"
                   :exportFilename="getTitle()"
                   :loading="loadingTable"
                   responsiveLayout="scroll"
                   :paginator="true"
                   :rows="10"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[10,20,50]"
                   currentPageReportTemplate="{first} al {last} de {totalRecords}">

          <template #header>
            <div class="table-header-container" v-if="dates">
              <div class="flex justify-content-between" v-if="report">
                <div class="header">
                  <div>
                    <h5>{{ getTitle() }} </h5>
                  </div>
                  <div class="flex justify-content-start" style="flex-wrap: wrap">
                    <div class="columns">
                      <LabelLight title="Categoría:" :content="getCategory(filters.reportCategory)"/>
                      <LabelLight title="Desde:" :content="dates && formatDateLongMoment(dates[0])"/>
                      <LabelLight title="Hasta:" :content="dates && formatDateLongMoment(dates[1])"/>
                    </div>
                    <div class="columns" style="width: 250px;">
                      <LabelLight title="Producto:"
                                  :content="filters && filters.producto && filters.producto.tproducto"/>
                      <LabelLight title="Modalidad:"
                                  :content="filters && filters.perfil_pago_premios  && filters.perfil_pago_premios.tperfil_pago_premios"/>
                    </div>
                    <div class="columns">
                      <LabelLight title="Bloque:"
                                  :content="filters && filters.comercializador  && filters.comercializador.tcomercializador"/>
                      <LabelLight title="Banca:" :content="filters && filters.banca  && filters.banca.tbanca"/>
                      <LabelLight title="Distribuidor:"
                                  :content="filters && filters.distribuidor  && filters.distribuidor.tdistribuidor"/>
                      <LabelLight title="Agencia:" :content="filters && filters.agencia  && filters.agencia.tagencia"/>
                    </div>
                  </div>

                </div>
                <div style="text-align: left">
                  <Button icon="pi pi-file-excel" @click="exportCSV($event)"/>
                </div>
              </div>

            </div>


          </template>
          <ColumnGroup type="header">
            <Row>
              <Column header="Categoría" :sortable="true" field="categoria" bodyStyle="text-align: center;"
                      headerStyle="width: 100px;"/>
              <Column :header="header?capitalizeFirstLetter(header):'---'" :sortable="true" :field="'t'+header"
                      bodyStyle="text-align: left;"
                      footerStyle="text-align: right;"
                      footer="Total:"/>
              <Column header="Venta" :sortable="true" field="venta_total"
                      bodyStyle="text-align: right;"
                      footerStyle="text-align: right;"
              >
                <template #body="{data}">
                  {{ formatCurrency(data.venta_total) }}
                </template>
                <template #footer>
                  {{ formatCurrency(total) }}
                </template>
              </Column>
              <Column header="Porcentaje" :sortable="true" field="venta_total"
                      :exportable="false"
                      bodyStyle="text-align: right;"
                      footerStyle="text-align: right;">
                <template #body="{data}">
                  {{ ((data.venta_total / total) * 100).toFixed(2) }}%
                </template>
                <template #footer>
                  {{ total ? '100,00%' : '0,00%' }}
                </template>
              </Column>
            </Row>
          </ColumnGroup>
        </DataTable>
      </div>
    </div>
  </div>
  <Toast/>
</template>

<script>

import generalMixin from '../../mixins/generalMixin.js'
import Filter from "@/components/Filters/Filter";
import ReportService from "../../service/backend/ReportService";
import LabelLight from "@/components/misc/Labels/LabelLight";
import FilterAnalyst from "@/components/Filters/FilterAnalyst";
// import Label2 from "@/components/misc/Labels/Label2";


export default {
  mixins: [generalMixin],
  components: {
    'Filter': Filter,
    'FilterAnalyst': FilterAnalyst,
    "LabelLight": LabelLight,
  },
  data() {
    return {
      loadingTable: false,
      report: null,
      filters: null,
      dates: null,
      total: 0,
      header: null,
      identity: null,
    }
  },
  saleService: null,
  created() {
    this.reportService = new ReportService()
    this.$primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubrer", "Noviembre", "Diciembre"];
    this.$primevue.config.locale.dayNamesMin = ["Do", "Lu", "Ma", "Mi", "Ju", "Vr", "Sa"];
    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
  },
  methods: {
    async getData(filters) {
      this.header = filters.selectedGroupBy;
      // Just in case only one date was selected
      filters.identity = this.identity;
      this.filters = filters;
      this.dates = filters.fechas;
      this.loadingTable = true;
      this.report = await this.reportService.getReport(filters);
      if (this.report && this.report.error) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: this.report.error, life: 3000});
        this.report= null;
        this.resetTotals();
      } else {
        this.calculateTotal();
      }
      if (this.report=== []) {
        this.$toast.add({severity: 'info', summary: 'Info', detail: "No records found.", life: 3000});
        this.report= null;
      }
      this.loadingTable = false
    },
    resetTotals() {
      this.total = 0;
    },
    calculateTotal() {
      let total = 0;
      this.report.forEach((item) => {
        total += Number(item.venta_total);
      });
      this.total = total;
      return total;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    getTitle() {
      const title = this.header ? this.header : null;
      return 'Reporte de Ventas por ' + this.capitalizeFirstLetter(title);
    },
  },
}
</script>
<style>
.selections {
  /*display: grid;*/
  /*grid-template: repeat(3, 1fr) / repeat(5, 1fr);*/
  /*grid-auto-flow: column dense;*/
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

}

.columns {
  margin: 10px;
}

</style>